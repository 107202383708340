import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { appURLs, GAME_NAME_MAP, GAME_SHORT_NAMES } from "@/app/constants.mjs";
import type { GameSymbol } from "@/app/games.mjs";
import { GAME_SYMBOL_APEX } from "@/game-apex/definition-symbol.mjs";
import { GAME_SYMBOL_CS2 } from "@/game-csgo/definition-symbol.mjs";
import { GAME_SYMBOL_FORTNITE } from "@/game-fortnite/definition-symbol.mjs";
import { GAME_SYMBOL_LOL } from "@/game-lol/definition-symbol.mjs";
import { GAME_SYMBOL_TFT } from "@/game-tft/definition-symbol.mjs";
import { GAME_SYMBOL_VAL } from "@/game-val/definition-symbol.mjs";
import MarketingFooter from "@/marketing/MarketingFooter.jsx";
import MarketingHeader from "@/marketing/MarketingHeader.jsx";
import {
  EncroachingImageSection,
  FeaturesSection,
  HeaderSection,
  PageContainer,
  PitchSection,
  StatsSection,
} from "@/marketing/Partnerships.style.jsx";
import { deleteInactiveGames } from "@/util/constants.mjs";
import SymbolMap from "@/util/symbol-map.mjs";

type GamePartnership = {
  cover: string;
  logo: string;
};

const GAMES = new SymbolMap<Partial<Record<GameSymbol, GamePartnership>>>({
  [GAME_SYMBOL_LOL]: {
    cover: `${appURLs.CDN}/partnerships/cover-lol-567a1.webp`,
    logo: `${appURLs.CDN}/partnerships/logo-lol-9df00.webp`,
  },
  [GAME_SYMBOL_VAL]: {
    cover: `${appURLs.CDN}/partnerships/cover-val-19b22.webp`,
    logo: `${appURLs.CDN}/partnerships/logo-val-e6a1d.webp`,
  },
  [GAME_SYMBOL_TFT]: {
    cover: `${appURLs.CDN}/partnerships/cover-tft-ccfa4.webp`,
    logo: `${appURLs.CDN}/partnerships/logo-tft-3cccf.webp`,
  },
  [GAME_SYMBOL_CS2]: {
    cover: `${appURLs.CDN}/partnerships/cover-csgo-e783b.webp`,
    logo: `${appURLs.CDN}/partnerships/logo-csgo-f0fcb.webp`,
  },
  [GAME_SYMBOL_APEX]: {
    cover: `${appURLs.CDN}/partnerships/cover-apex-9950d.webp`,
    logo: `${appURLs.CDN}/partnerships/logo-apex-bcfd6.webp`,
  },
  [GAME_SYMBOL_FORTNITE]: {
    cover: `${appURLs.CDN}/partnerships/cover-fn-7b182.webp`,
    logo: `${appURLs.CDN}/partnerships/logo-fn-fd54d.webp`,
  },
});
deleteInactiveGames(GAMES);

const STATS: Array<{ value: string; desc: Translation }> = [
  {
    value: "8M+",
    desc: ["home:partnerships.stats1.desc", "Total Blitz App users"],
  },
  {
    value: "42x",
    desc: ["home:partnerships.stats2.desc", "User growth over 3 years"],
  },
  {
    value: "200+",
    desc: ["home:partnerships.stats3.desc", "Countries supported"],
  },
  {
    value: "200M",
    desc: ["home:partnerships.stats4.desc", "Monthly games played with Blitz"],
  },
  {
    value: "10M+",
    desc: [
      "home:partnerships.stats5.desc",
      "Users across all of our portfolio sites",
    ],
  },
];

const PARTNERSHIPS_MAIL_LINK = "team@blitz.gg";

export default function Partnerships() {
  const [selectedGame, setSelectedGame] = useState<keyof typeof GAMES>(
    SymbolMap.keys(GAMES)[0],
  );

  const [coverImage, setCoverImage] = useState(GAMES[selectedGame].cover);

  useEffect(() => {
    const newImg = new Image();

    newImg.onload = function (this: HTMLImageElement) {
      setCoverImage(this.src);
    };

    newImg.src = GAMES[selectedGame].cover;

    return () => {
      newImg.onload = null;
    };
  }, [selectedGame]);

  const { t } = useTranslation();

  return (
    <PageContainer>
      <MarketingHeader />

      <HeaderSection $bgImage={coverImage}>
        <div>
          <h1>{t("home:partnerships.partnerships.title", "Partnerships")}</h1>
          <p>
            {t(
              "home:partnerships.partnerships.desc",
              "Blitz offers unique opportunities for your brand to reach millions of gamers.",
            )}
          </p>

          <ul>
            {SymbolMap.entries(GAMES).map(([gameId, game]) => (
              <li
                key={GAME_SHORT_NAMES[gameId]}
                onClick={() => setSelectedGame(gameId)}
                {...(selectedGame === gameId ? { "aria-current": true } : {})}
              >
                <img src={game.logo} alt={t(...GAME_NAME_MAP[gameId])} />
              </li>
            ))}
          </ul>
        </div>
      </HeaderSection>

      <StatsSection>
        <h3>
          {t(
            "home:partnerships.stats.title",
            "Millions of gamers worldwide use Blitz every day to improve their play. Just check out the numbers:",
          )}
        </h3>

        <ul>
          {STATS.map((stats, index) => (
            <li key={index}>
              <h4>{stats.value}</h4>
              <hr />
              <p>{t(...stats.desc)}</p>
            </li>
          ))}
        </ul>
      </StatsSection>

      <FeaturesSection>
        <div>
          <div className="text-block">
            <h3>
              {t("home:partnerships.advertising.title", "In-App Advertising")}
            </h3>
            <p>
              {t(
                "home:partnerships.advertising.body",
                "Partners can run display ads within Blitz that reach our global userbase. Target gamers from Spain to South Korea!",
              )}
            </p>
            <a href={`mailto:${PARTNERSHIPS_MAIL_LINK}`}>
              {t("home:partnerships.contactus", "Contact Us")}
            </a>
          </div>

          <div className="image-block">
            <img
              src={`${appURLs.CDN}/partnerships/advertising-9f660.webp`}
              role="presentation"
              width={740}
              height={415}
            />
          </div>
        </div>

        <div className="reverse">
          <div className="text-block">
            <h3>{t("common:navigation.battles", "Blitz Battles")}</h3>
            <p>
              {t(
                "home:partnerships.challenges.body",
                "Connect with customers through unique gamified experiences! Sponsor a Blitz Battles event and Blitz users will compete to win prizes from your brand.",
              )}
            </p>
            <a href={`mailto:${PARTNERSHIPS_MAIL_LINK}`}>
              {t("home:partnerships.contactus", "Contact Us")}
            </a>
          </div>

          <div className="image-block">
            <img
              src={`${appURLs.CDN}/partnerships/challenges-e9a0d.webp`}
              role="presentation"
              width={740}
              height={415}
            />
          </div>
        </div>
      </FeaturesSection>

      <PitchSection>
        <div>
          <h4>
            {t("home:partnerships.influencer.title", "Influencer partnerships")}
          </h4>
          <p>
            {t(
              "home:partnerships.influencer.desc",
              "We're always looking for talented creators to help spread the word about Blitz. Contact us today if you love making content and want to promote our app!",
            )}
          </p>
          <a href={`mailto:${PARTNERSHIPS_MAIL_LINK}`}>
            {t("home:partnerships.sendUs", "Send Us Your Pitch")}
          </a>
        </div>
      </PitchSection>

      <EncroachingImageSection>
        <div className="row">
          <div className="item">
            <img
              src={`${appURLs.CDN}/partnerships/influencer1-91996.webp`}
              role="presentation"
              width={436}
              height={522}
            />
          </div>

          <div className="col">
            <div className="item">
              <img
                src={`${appURLs.CDN}/partnerships/influencer2-457b1.webp`}
                role="presentation"
                width={440}
                height={253}
              />
            </div>

            <div className="item">
              <img
                src={`${appURLs.CDN}/partnerships/influencer3-bb46f.webp`}
                role="presentation"
                width={440}
                height={253}
              />
            </div>
          </div>
        </div>
      </EncroachingImageSection>

      <MarketingFooter expanded />
    </PageContainer>
  );
}

export function meta() {
  return {
    title: ["home:meta.partnerships.title", "Blitz.gg - Partnerships"],
    description: [
      "home:meta.partnerships.description",
      "Partner with Blitz, elevate your brand, and reach gamers worldwide.",
    ],
  };
}
