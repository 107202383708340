import { styled } from "goober";
import {
  desktop,
  mobile,
  mobileMedium,
  tablet,
} from "clutch/src/Style/style.mjs";

export const PageContainer = styled("div")`
  --section-gap: var(--sp-30);
  --content-max-width: min(1200px, 100vw);

  width: 100%;
  margin: 0 auto;

  footer {
    margin-top: var(--sp-24);
  }
`;

const Section = styled("section")`
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-inline: auto;

  ${desktop} {
    padding-inline: var(--sp-12);
  }

  ${mobile} {
    padding-inline: var(--sp-6);
  }
`;
export const HeaderSection = styled(Section)<{ $bgImage: string }>`
  align-items: center;
  justify-content: center;

  ${"" /* due to design */}
  height: 768px;

  color: var(--white);

  background: url(${(props) => props.$bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: var(--content-max-width);

    text-align: center;

    h1 {
      font-weight: 500;
      font-size: var(--sp-3);
      margin-bottom: var(--sp-6);
      text-transform: uppercase;
    }

    p {
      font-weight: 700;
      font-size: var(--sp-15);
      line-height: var(--sp-19);
      letter-spacing: -0.009em;

      margin-bottom: var(--sp-13);

      ${mobile} {
        font-size: var(--sp-12);
        line-height: var(--sp-16);
      }

      ${mobileMedium} {
        font-size: var(--sp-7);
        line-height: var(--sp-10);
      }
    }

    ul {
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      gap: var(--sp-6);

      width: 100%;

      ${desktop} {
        grid-template-columns: repeat(3, 1fr);
        grid-auto-flow: unset;
      }

      ${mobile} {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: unset;
      }

      li {
        display: flex;
        align-items: center;
        justify-content: center;

        ${"" /* due to design */}
        height: 212px;

        border: 1px solid rgba(227, 229, 234, 0.15);
        border-radius: var(--sp-2);

        transition: background 0.2s ease-in-out;

        cursor: pointer;

        &:hover,
        &[aria-current="true"] {
          background: rgba(227, 229, 234, 0.15);
          backdrop-filter: blur(20px);
        }

        ${desktop} {
          ${"" /* due to design */}
          height: 115px;
        }

        ${tablet} {
          ${"" /* due to design */}
          height: 94px;
        }
      }
    }
  }
`;

export const StatsSection = styled(Section)`
  justify-content: center;
  gap: var(--sp-12);

  padding: var(--sp-30) var(--sp-18);

  color: var(--white);

  background: linear-gradient(
    102.98deg,
    rgb(255, 17, 45) -0.14%,
    rgb(207, 16, 80) 100%
  );

  h3 {
    align-self: flex-start;

    ${"" /* due to design */}
    max-width: 790px;

    font-weight: 700;
    font-size: var(--sp-8);
    line-height: var(--sp-11);
    letter-spacing: -0.009em;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: var(--sp-6);

    width: 100%;

    ${desktop} {
      grid-template-columns: repeat(3, 1fr);
    }

    ${mobile} {
      grid-template-columns: repeat(2, 1fr);
    }

    li {
      display: flex;
      flex-direction: column;
      gap: var(--sp-4);

      padding: var(--sp-6);

      border: 1px solid rgba(227, 229, 234, 0.5);
      border-radius: var(--sp-2);

      h4 {
        font-size: var(--sp-12);
        font-weight: 700;
        line-height: var(--sp-16);
      }

      hr {
        width: var(--sp-24);
        margin: 0;

        border: 1px solid rgba(227, 229, 234, 0.5);
        border-radius: var(--sp-0_5);
      }

      p {
        max-width: var(--sp-30);

        font-weight: 600;
        font-size: var(--sp-4_5);
        line-height: var(--sp-7);
        letter-spacing: -0.009em;
      }
    }
  }
`;

export const FeaturesSection = styled(Section)`
  justify-content: center;
  gap: var(--sp-16);

  padding: var(--sp-30) var(--sp-18);

  color: var(--white);

  ${mobile} {
    padding: var(--sp-16) var(--sp-8);
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.reverse {
      flex-direction: row-reverse;
    }

    ${mobile} {
      flex-direction: column-reverse !important;
      gap: var(--sp-12);
    }

    div.text-block {
      display: flex;
      flex-direction: column;
      gap: var(--sp-6);

      width: 30%;
      ${"" /* due to design */}
      max-width: 325px;

      ${mobile} {
        align-items: center;

        width: 100%;
        max-width: unset;

        text-align: center;
      }

      h3 {
        font-size: var(--sp-14);
        font-weight: 700;
        line-height: var(--sp-19);

        ${tablet} {
          font-size: var(--sp-10);
          line-height: var(--sp-14);
        }

        ${mobile} {
          font-size: var(--sp-8);
          line-height: var(--sp-11);
        }
      }

      p {
        color: var(--shade1);

        font-weight: 500;
        font-size: var(--sp-4);
        line-height: var(--sp-7);
        letter-spacing: -0.009em;
      }

      a {
        width: fit-content;
        padding: var(--sp-4) var(--sp-12);

        text-align: center;

        border-radius: var(--sp-2);
        background: linear-gradient(
          316deg,
          rgb(206, 15, 80) 26%,
          rgb(254, 17, 45) 75%
        );

        font-weight: 700;
        font-size: var(--sp-4);
        line-height: var(--sp-7);
        letter-spacing: -0.009em;

        transition: all 0.2s ease-in-out;

        cursor: pointer;

        &:hover {
          background: linear-gradient(
            316deg,
            rgb(189, 35, 87) 46%,
            rgb(234, 45, 68) 86%
          );
        }
      }
    }

    div.image-block {
      width: 60%;

      ${mobile} {
        width: 100%;
        ${"" /* due to design */}
        max-width: 514px;
      }

      img {
        width: 100%;
        height: 100%;

        border-radius: var(--sp-2);

        object-fit: cover;
      }
    }
  }
`;

export const PitchSection = styled(Section)`
  padding: 0 var(--sp-18);
  padding-bottom: var(--section-gap);

  color: var(--white);
  text-align: center;

  background: linear-gradient(
    102.98deg,
    rgb(255, 17, 45) -0.14%,
    rgb(207, 16, 80) 100%
  );

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--sp-6);

    ${"" /* due to design */}
    max-width: 1044px;
    margin: 0 auto;
    padding: var(--section-gap) 0;
  }

  h4 {
    font-weight: 500;
    font-size: var(--sp-3);
    text-transform: uppercase;
  }

  p {
    font-weight: 400;
    font-size: var(--sp-7);
    line-height: var(--sp-10);
  }

  a {
    padding: var(--sp-4) var(--sp-6_5);

    color: #dd344a;
    text-align: center;

    background: var(--white);
    border-radius: var(--sp-2);

    font-size: var(--sp-4);
    font-weight: 700;
    line-height: var(--sp-7);

    transition: all 0.2s ease-in-out !important;

    &:hover {
      color: var(--white);
      background: #dd344a;
    }

    ${mobile} {
      width: 100%;
    }
  }
`;

export const EncroachingImageSection = styled(Section)`
  --spacing: var(--sp-4);

  gap: var(--spacing);

  max-width: var(--content-max-width);
  ${"" /* due to design */}
  height: 522px;
  margin: 0 auto;
  margin-top: calc(var(--section-gap) * -1);

  ${desktop} {
    padding: 0 var(--sp-18);
  }

  div {
    flex: 1 1 0;

    display: flex;
    gap: var(--spacing);

    border-radius: var(--br-lg);

    overflow: hidden;
  }

  div.row {
    flex-direction: row;
  }

  div.col {
    flex-direction: column;
  }

  img {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  ${mobile} {
    display: block;

    height: auto;
    padding: var(--spacing);

    white-space: nowrap;
    overflow-x: auto;

    div {
      flex: unset;

      display: inline-flex;
    }

    div.col {
      flex-direction: row;
    }

    div.item {
      ${"" /* due to design */}
      width: 350px;
      min-width: 220px;
      height: 450px;
    }
  }

  ${mobileMedium} {
    div.item {
      ${"" /* due to design */}
      width: 220px;
      min-width: 220px;
      height: 300px;
    }
  }
`;
